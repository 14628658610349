import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHighContrastMode: boolean
}

export const InfoModal = ({
  isOpen,
  handleClose,
  isHighContrastMode,
}: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          value="W"
          status="correct"
          isHighContrastMode={isHighContrastMode}
        />
        <Cell value="E" isHighContrastMode={isHighContrastMode} />
        <Cell value="A" isHighContrastMode={isHighContrastMode} />
        <Cell value="R" isHighContrastMode={isHighContrastMode} />
        <Cell value="Y" isHighContrastMode={isHighContrastMode} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter W is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" isHighContrastMode={isHighContrastMode} />
        <Cell value="I" isHighContrastMode={isHighContrastMode} />
        <Cell
          value="L"
          status="present"
          isHighContrastMode={isHighContrastMode}
        />
        <Cell value="O" isHighContrastMode={isHighContrastMode} />
        <Cell value="T" isHighContrastMode={isHighContrastMode} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="V" isHighContrastMode={isHighContrastMode} />
        <Cell value="A" isHighContrastMode={isHighContrastMode} />
        <Cell value="G" isHighContrastMode={isHighContrastMode} />
        <Cell
          value="U"
          status="absent"
          isHighContrastMode={isHighContrastMode}
        />
        <Cell value="E" isHighContrastMode={isHighContrastMode} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter U is not in the word in any spot.
      </p>
    </BaseModal>
  )
}
