export const WORDS = [
  'stagg',
  'tulsa',
  'corso',
  'canes',
  'geaux',
  'kelly',
  'zippy',
  'guins',
  'idaho',
  'bulls',
  'miner',
  'rhule',
  'bucky',
  'aubie',
  'mason',
  'roary',
  'monty',
  'meyer',
  'visor',
  'saban',
  'urban',
  'boone',
  'flash',
  'state',
  'bison',
  'route',
  'jones',
  'title',
  'terps',
  'royal',
  'pylon',
  'lobos',
  'davie',
  'marco',
  'swamp',
  'herky',
  'umass',
  'deacs',
  'weber',
  'cougs',
  'bible',
  'lovie',
  'tebow',
  'harry',
  'aggie',
  'cards',
  'frost',
  'devil',
  'dores',
  'vandy',
  'cosmo',
  'alamo',
  'ducks',
  'tribe',
  'drake',
  'catch',
  'beavs',
  'shark',
  'yotes',
  'jacks',
  'demon',
  'maize',
  'rhody',
  'lions',
  'lamar',
  'dykes',
  'miles',
  'dimel',
  'bigal',
  'stars',
  'holtz',
  'uconn',
  'benny',
  'butch',
  'hayes',
  'hoyas',
  'brown',
  'wazzu',
  'gigem',
  'tomey',
  'dukes',
  'smart',
  'cincy',
  'jimbo',
  'maine',
  'cleat',
  'wells',
  'pawla',
  'alums',
  'danes',
  'tweet',
  'texas',
  'carey',
  'aloha',
  'racer',
  'mules',
  'upenn',
  'riley',
  'reser',
  'slide',
  'sicem',
  'bruce',
  'score',
  'smith',
  'james',
  'rocky',
  'flyer',
  'bully',
  'goldy',
  'field',
  'noles',
  'ching',
  'tiger',
  'kenan',
  'clark',
  'cocky',
  'fleck',
  'sugar',
  'richt',
  'brave',
  'hawgs',
  'leach',
  'irish',
  'point',
  'davis',
  'gundy',
  'rowdy',
  'blues',
  'akron',
  'hokie',
  'vegas',
  'bucks',
  'coach',
  'blaze',
  'hawks',
  'dawgs',
  'frogs',
  'croot',
  'peach',
  'blitz',
  'heinz',
  'husky',
  'polls',
  'roses',
  'coogs',
  'rival',
  'aztec',
  'boise',
  'zebra',
  'miami',
  'eagle',
  'woody',
  'swoop',
  'kirby',
  'rufus',
  'bands',
  'blaik',
  'green',
  'rebel',
  'gator',
  'verne',
  'bears',
  'bruin',
  'horns',
  'valpo',
  'brohm',
  'cajun',
  'snaps',
  'camel',
  'grobe',
]
