import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE } from '../constants/strings'

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  isDarkMode: boolean,
  isHighContrastMode: boolean,
  isDiscord: boolean
) => {
  navigator.clipboard.writeText(
    `${GAME_TITLE} ${solutionIndex} ${lost ? 'X' : guesses.length}/6${
      isHardMode ? '*' : ''
    }\n\n` +
      generateEmojiGrid(guesses, isDarkMode, isHighContrastMode, isDiscord) +
      `\n\nhttps://cfbordle.redditcfb.com`
  )
}

export const generateEmojiGrid = (
  guesses: string[],
  isDarkMode: boolean,
  isHighContrastMode: boolean,
  isDiscord: boolean
) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return (
        guess
          .split('')
          .map((_, i) => {
            switch (status[i]) {
              case 'correct':
                return '🏈'
              case 'present':
                return isHighContrastMode ? '🟦' : '🟨'
              default:
                return isDarkMode ? '⬛' : '⬜'
            }
          })
          .join('') + (isDiscord ? ` ||\`${guess}\`||` : '')
      )
    })
    .join('\n')
}
